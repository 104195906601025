

<template>
  <!-- Modal Start -->
  <div
      v-if="modal && item"
      class="
      h-screen
      w-screen
      z-50
      fixed
      top-0
      right-0
      flex flex-col
      items-center
      justify-center
      font-sans
    "
  >
    <div
        @click.self="closeModal"
        class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
    ></div>

    <div
        class="
        modal-container
        bg-white
        w-11/12
        md:max-w-md
        mx-auto
        rounded
        shadow-lg
        z-50
        overflow-y-auto
      "
    >
      <div
          @click="closeModal"
          class="
          modal-close
          absolute
          top-0
          right-0
          cursor-pointer
          flex flex-col
          items-center
          mt-4
          mr-4
          text-white text-sm
          z-50
        "
      >
        <svg
            class="fill-current text-white"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
        >
          <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          ></path>
        </svg>
      </div>

      <div class="modal-content py-8 text-left px-6">
        <h3 class="text-gray-900 text-2xl font-bold pb-4">Edit order id: {{ item.id }}</h3>
        <!--Content START-->
        <!-- Name Start -->
        <div class="mb-2">
          <div class="relative inline-block w-full text-left">
            <InputText
                label="Name*"
                attribute="title"
                placeholder="Example: Pera Peric"
                :submitStatus="submitStatus"
                v-model.trim="$v.item.full_name.$model"
                :v="$v.item.full_name"
                :errorMessages="{
                required: 'Name is required',
              }"
            />
          </div>
        </div>
        <!-- Name End -->
        <!-- Slug Start -->
        <div class="mb-2">
          <div class="relative inline-block w-full text-left">
            <InputText
                label="Email*"
                attribute="price"
                placeholder="Example: peraperic@gmail.com"
                :submitStatus="submitStatus"
                v-model.trim="$v.item.email.$model"
                :v="$v.item.email"
                :errorMessages="{
                required: 'Email is required',
              }"
            />
          </div>
      </div>
          <div class="mb-2">
            <div class="relative inline-block w-full text-left">
              <InputText
                  label="Phone*"
                  attribute="phone"
                  placeholder="Example: 0655010348"
                  :submitStatus="submitStatus"
                  v-model.trim="$v.item.phone.$model"
                  :v="$v.item.phone"
                  :errorMessages="{
                required: 'Phone is required',
              }"
              />
            </div>
          </div>
        <div class="mb-2">
          <div class="relative inline-block w-full text-left">
            <InputText
                label="Time from*"
                attribute="time_from"
                placeholder="Example: 8;30"
                :submitStatus="submitStatus"
                v-model.trim="$v.item.time_from.$model"
                :v="$v.item.time_from"
                :errorMessages="{
                required: 'Time from is required',
              }"
            />
          </div>
        </div>
        <div class="mb-2">
          <div class="relative inline-block w-full text-left">
            <InputText
                label="Time to*"
                attribute="time_to"
                placeholder="Example: 23:00"
                :submitStatus="submitStatus"
                v-model.trim="$v.item.time_to.$model"
                :v="$v.item.time_to"
                :errorMessages="{
                required: 'Phone is required',
              }"
            />
          </div>
        </div>          <div class="mb-2">
        <div class="relative inline-block w-full text-left">
          <InputText
              label="Date from*"
              attribute="date_from"
              placeholder="Example: 23.04.2023."
              :submitStatus="submitStatus"
              v-model.trim="$v.item.date_from.$model"
              :v="$v.item.date_from"
              :errorMessages="{
                required: 'Date from is required',
              }"
          />
        </div>
      </div>          <div class="mb-2">
        <div class="relative inline-block w-full text-left">
          <InputText
              label="Date to*"
              attribute="date_to"
              placeholder="Example: 26.04.2023."
              :submitStatus="submitStatus"
              v-model.trim="$v.item.date_to.$model"
              :v="$v.item.date_to"
              :errorMessages="{
                required: 'Date to is required',
              }"
          />
        </div>
      </div>
        <div class="mb-2">
        <div class="relative inline-block w-full text-left">
          <InputText
              label="Sum*"
              attribute="total"
              placeholder="Example: 5400"
              :submitStatus="submitStatus"
              v-model.trim="$v.item.total.$model"
              :v="$v.item.total"
              :errorMessages="{
                required: 'Total sum is required',
              }"
          />
        </div>
      </div>
        <div class="mb-2">
        <div class="relative inline-block w-full text-left">
          <InputText
              label="Days*"
              attribute="days"
              placeholder="Example: 6"
              :submitStatus="submitStatus"
              v-model.trim="$v.item.days_total.$model"
              :v="$v.item.days_total"
              :errorMessages="{
                required: 'Days is required',
              }"
          />
        </div>
      </div>
        <div class="mb-2">
          <div class="relative inline-block w-full text-left">
            <label for="status" class="block text-sm font-medium text-gray-700">Status*</label>
            <select
                v-model="item.status"
                id="status"
                name="status"
                class="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option :value="1">Reserved</option>
              <option :value="2">Parked</option>
              <option :value="3">Cancelled</option>
              <option :value="4">Completed</option>
              <option :value="5">Free</option>
            </select>
          </div>
        </div>
        <!-- Slug End -->
        <!--Content END-->
        <!--Buttons START-->
        <div class="flex justify-end pt-2">
          <button
              @click="closeModal"
              class="
              px-4
              bg-transparent
              p-3
              rounded-lg
              text-indigo-500
              hover:bg-gray-100 hover:text-indigo-400
              mr-2
            "
          >
            Close
          </button>
          <button
              class="
              bg-blue-600
              hover:bg-blue-700
              text-white
              font-bold
              py-2
              px-4
              rounded
              focus:outline-none
            "
              @click="updateObject"
              v-if="submitStatus !== 'PENDING'"
          >
            Save price
          </button>
          <button
              type="button"
              class="
              inline-flex
              items-center
              px-4
              py-2
              border border-transparent
              text-base
              leading-6
              font-medium
              rounded-md
              text-white
              bg-indigo-600
              hover:bg-indigo-500
              focus:outline-none
              focus:border-indigo-700
              focus:shadow-outline-indigo
              active:bg-indigo-700
              transition
              ease-in-out
              duration-150
              cursor-not-allowed
            "
              disabled=""
              v-if="submitStatus === 'PENDING'"
          >
            <svg
                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
              <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
              ></circle>
              <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Saving...
          </button>
        </div>
        <!--Buttons END-->
      </div>
    </div>
  </div>
  <!-- Modal End -->
</template>

<script>
import InputText from "./InputText";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    InputText,
  },
  name: "EditCategory",
  props: ["modal", "item"],
  data() {
    return {
      submitStatus: null,
    };
  },
  validations: {
    item: {
      full_name: {
        required,
      },
      email: {
        required,
      },
      phone: {
        required,
      },
      status: {
        required,
      },
      time_from: {
        required,
      },
      time_to: {
        required,
      },
      date_from: {
        required,
      },
      date_to: {
        required,
      },
      total: {
        required,
      },
      days_total: {
        required,
      },
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    async updateObject() {
      // define a form data
      let data = new FormData();
      data.append("full_name", this.item.full_name);
      data.append("phone", this.item.phone);
      data.append("email", this.item.email);
      data.append("status", this.item.status);
      data.append("date_from", this.item.date_from);
      data.append("date_to", this.item.date_to);
      data.append("time_from", this.item.time_from);
      data.append("time_to", this.item.time_to);
      data.append("status", this.item.status);
      data.append("total", this.item.total);
      data.append("days_total", this.item.days_total);
      data.append("status", this.item.status);
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
        this.$vToastify.error("All fields are required", "Error");
      } else {
        // send API request to back end
        this.submitStatus = "PENDING";
        this.axios
            .post(
                process.env.VUE_APP_BACKEND_URL +
                "/order/update/" +
                this.item.id,
                data
            )
            .then((dataRes) => {
              this.$emit("reloadObjects");
              this.$vToastify.success(dataRes.data.message, "Success");
              this.submitStatus = "OK";
              this.closeModal();
            })
            .catch((error) => {
              this.$vToastify.error(error.message, "Error");
              this.submitStatus = "OK";
              this.$emit("reloadObjects");
            });
      }
    },
  },
};
</script>
