<template>
  <div>
    <h3 class="text-gray-900 text-3xl font-bold">Dashboard</h3>
    <div v-if="orders">
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-6 mt-4">
        <NumberOfOpenOrders :orders="orders.data" />
        <TotalIncome   :orders="orders.data" />
        <UserWithMostOrders  :orders="orders.data" />
        <NumberOfUsers   :orders="orders.data" />
      </div>
      <div class="flex flex-col mt-8">
        <div class="py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <IncomeByMonth :orders="orders.data" :selectedYear="selectedYear" @switchYear="switchYear" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import NumberOfOpenOrders from "../components/Dashboard/NumberOfOpenOrders";
import NumberOfUsers from "../components/Dashboard/NumberOfUsers";
import UserWithMostOrders from "../components/Dashboard/UserWithMostOrders";
import TotalIncome from "../components/Dashboard/TotalIncome";
import IncomeByMonth from "../components/Dashboard/IncomeByMonth";

export default {
  components: {
    UserWithMostOrders,
    NumberOfOpenOrders,
    NumberOfUsers,
    TotalIncome,
    IncomeByMonth
  },
  data() {
    return {
      orders: 0,
      selectedYear: new Date().getFullYear(), // Set to current year dynamically
    };
  },
  mounted() {
    this.loadObject();
  },
  methods: {
    async loadObject() {
      let url = process.env.VUE_APP_BACKEND_URL + "/order/get?paginate=1000";
      this.axios
          .get(url)
          .then((dataRes) => {
            this.orders = dataRes.data.orders;
          })
          .catch((error) => {
            let response = JSON.parse(error.request.response);
            this.$vToastify.error(response.message, "Error");
          });
    },
    switchYear(year) {
      this.selectedYear = year;
    },
  },
};
</script>
