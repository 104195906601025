import Vue from 'vue';
import App from './App';
import Vuelidate from 'vuelidate';
import axios from 'axios';
import router from "./router";
import VueAxios from 'vue-axios';
import store from './store';
import VueConfirmDialog from 'vue-confirm-dialog/src/vue-confirm-dialog.vue';
import VueToastify from "vue-toastify";
import wysiwyg from "vue-wysiwyg";
import CountryFlag from 'vue-country-flag';

Vue.component('country-flag', CountryFlag);
Vue.use(require('vue-moment'));

// Styles
import '@/assets/styles/main.scss';
import '@/assets/styles/app.css';
import "vue-wysiwyg/dist/vueWysiwyg.css";
import 'hooper/dist/hooper.css';

// Usage
Vue.use(wysiwyg, {
  // { [module]: boolean (set true to hide) }
  hideModules: {
    "image": true,
    "table": true,
    "orderedList": true,
    "unorderedList": true,
    "removeFormat": true,
    "link": true
 },
  locale: 'de',
}); // config is optional. more below
Vue.use(VueToastify, {
  theme: 'light',
  hideProgressbar: true
});
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.use(Vuelidate)

Vue.config.productionTip = false

//add plugins configurations
import "./plugins/axios";
Vue.use(VueAxios, axios)



new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
